





























































import GetValuationModel from 'truemarket-modules/src/models/api/valuations/GetValuationModel'
import { Component, Prop, Vue } from 'vue-property-decorator'
import CustomerProfile from './Components/CustomerProfile.vue'
import InlineEditable from './Components/InlineEditable.vue'
import ValuerProfile from './Components/ValuerProfile.vue'

import { IReportBlock, IReportBlockDataModel } from './IReportBlock'

@Component({
  components: {
    ValuerProfile,
    CustomerProfile,
    InlineEditable
  }
})
export default class CoverPageBlock extends Vue implements IReportBlock {
  @Prop({ required: true })
  private readonly reportData!: GetValuationModel;

  private address = '';

  async GetData (): Promise<IReportBlockDataModel> {
    return {} as IReportBlockDataModel
  }

  GetAddress (): string {
    if (this.reportData.Property.Address.UnitNumber) {
      this.address = this.reportData.Property.Address.UnitNumber + ' / ' + this.reportData.Property.Address.StreetNumber + ' ' + this.reportData.Property.Address.StreetName
    } else {
      this.address = this.reportData.Property.Address.StreetNumber + ' ' + this.reportData.Property.Address.StreetName
    }

    return this.address
  }
}
